<template>
    <div class="bg re_sizing">
        
    </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
    window.open('https://mp.weixin.qq.com/s/_rrPRzgnYqEwvksHu0vUXg',"_blank")
})
</script>

<style lang="less" scoped>
.bg {
    padding-top: 50px;
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}
</style>